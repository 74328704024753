import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homethree-about',
    templateUrl: './homethree-about.component.html',
    styleUrls: ['./homethree-about.component.scss']
})
export class HomethreeAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/about/img4.png'
        }
    ]
    aboutContent: Content[] = [
        {
            title: 'Data Science Training + Industry Experience',
            paragraphText1: 'Werkbite harnesses data science to empower businesses with actionable insights and predictive analytics, driving informed decisions and transformative outcomes.',
            paragraphText2: 'At Werkbite, we leverage data science to empower businesses with actionable insights and predictive analytics, enabling informed decisions and transformative outcomes.',
            paragraphText3: 'Werkbite excels in data science, delivering actionable insights and predictive analytics that drive informed decisions and transformative business outcomes.',
            defaultBtnIcon: 'flaticon-right',
            defaultBtnText: 'More About Us',
            defaultBtnLink: 'about-us'
        }
    ]
    contentList: List[] = [
        {
            icon: 'flaticon-tick',
            title: 'What is regularization and why it is useful',
        },
        {
            icon: 'flaticon-tick',
            title: 'Perhaps the most popular data science methodologies'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    paragraphText3 : string;
    defaultBtnIcon : string;
    defaultBtnText : string;
    defaultBtnLink : string;
}
class List {
    icon : string;
    title : string;
}