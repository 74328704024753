import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homethree-courses',
    templateUrl: './homethree-courses.component.html',
    styleUrls: ['./homethree-courses.component.scss']
})
export class HomethreeCoursesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Online Data Science Courses',
            paragraphText: 'Werkbite offers online data science courses covering essential skills like machine learning, statistical analysis, and data visualization, designed to equip you with practical knowledge and hands-on experience for career advancement.'
        }
    ]
    coursesInfo: Info[] = [
        {
            paragraphText: 'Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion',
            linkText: 'Join Free Now.',
            link: 'contact'
        }
    ]
    singleCoursesBox: coursesContent[] = [
        {
            courseImg: 'assets/img/courses/img1.jpg',
            coursePrice: 'Free',
            coursePriceClass: 'free',
            authorImg: 'assets/img/user1.jpg',
            authorName: 'Alex Morgan',
            title: 'Introduction to Saas Product development',
            link: 'courses-details',
            paragraphText: 'Werkbite specializes in SaaS product development, creating scalable and secure software solutions delivered via the cloud. Our process involves designing, developing, and optimizing applications to meet user needs, ensure high performance, and drive business growth with continuous updates and support.',
            courseDuration: '8 Weeks Long',
            studentApply: 'Available Now'
        },
        {
            courseImg: 'assets/img/courses/img2.jpg',
            coursePrice: 'Free',
            coursePriceClass: 'paid',
            authorImg: 'assets/img/user2.jpg',
            authorName: 'Sarah Taylor',
            title: 'Introduction to API Service deployment CI/CD',
            link: 'courses-details',
            paragraphText: 'Werkbite integrates API service deployment with CI/CD (Continuous Integration/Continuous Deployment) practices to streamline software delivery. Our approach automates testing, integration, and deployment processes, ensuring reliable, scalable, and efficient updates to APIs, and accelerating time-to-market while maintaining high quality and performance.',
            courseDuration: '7 Weeks Long',
            studentApply: 'Available Now'
        },
        {
            courseImg: 'assets/img/courses/img3.jpg',
            coursePrice: 'Free',
            coursePriceClass: 'paid',
            authorImg: 'assets/img/user3.jpg',
            authorName: 'David Warner',
            title: 'Data Science: Inference and Modeling',
            link: 'courses-details',
            paragraphText: 'Werkbite excels in data science through advanced inference and modeling techniques. We use statistical methods and machine learning algorithms to derive actionable insights from data, build predictive models, and validate hypotheses, enabling businesses to make data-driven decisions and enhance performance.',
            courseDuration: '2 Weeks Long',
            studentApply: 'Not Available'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class Info {
    paragraphText : string;
    linkText : string;
    link : string;
}
class coursesContent {
    courseImg : string;
    coursePrice : string;
    coursePriceClass : string;
    authorImg : string;
    authorName : string;
    title : string;
    link : string;
    paragraphText : string;
    courseDuration : string;
    studentApply : string;
}