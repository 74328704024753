import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homethree-featured-services',
    templateUrl: './homethree-featured-services.component.html',
    styleUrls: ['./homethree-featured-services.component.scss']
})
export class HomethreeFeaturedServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleFeaturedServicesItem: FeaturedServicesItem[] = [
        {
            icon: 'assets/img/icon/icon1.png',
            title: 'Online',
            paragraphText: 'Werkbite provides online feature services, including custom software development, AI solutions, and cloud-based applications.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/icon/icon2.png',
            title: 'In-person',
            paragraphText: 'Werkbite offers in-person services including consultations and workshops, delivering tailored IT solutions and hands-on support to address specific business needs and drive growth.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/icon/icon3.png',
            title: 'Business',
            paragraphText: 'Werkbite delivers tailored business solutions through advanced IT services, AI integration, and data analytics, designed to optimize operations, enhance performance, and drive growth.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        }
    ]

}
class FeaturedServicesItem {
    icon : string;
    title : string;
    paragraphText : string;
    btnIcon : string;
    btnText : string;
    link : string;
}