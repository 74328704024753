import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-related-services',
    templateUrl: './related-services.component.html',
    styleUrls: ['./related-services.component.scss']
})
export class RelatedServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'More Services You Might Like'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Data Analytics',
            paragraphText: 'Werkbite maximizes business potential with powerful data analytics solutions, uncovering actionable insights for informed decision-making and strategic growth.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'AI & ML Development',
            paragraphText: 'Werkbite pioneers AI and ML solutions, crafting intelligent systems that drive efficiency, innovation, and competitive advantage for your business.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Data Science',
            paragraphText: 'At Werkbite, we leverage data science to empower businesses with actionable insights and predictive analytics, enabling informed decisions and transformative outcomes',
            link: 'services-details',
            linkText: 'View Details'
        }
    ]

}
class sectionTitleContent {
    title : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}