import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-case-studies',
    templateUrl: './homeone-case-studies.component.html',
    styleUrls: ['./homeone-case-studies.component.scss']
})
export class HomeoneCaseStudiesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleCaseStudyItem: singleCaseStudyItemContent[] = [
        {
            subTitle: 'Case study #1',
            title: 'Data Science in Pharmaceutical Industries',
            paragraphText1: 'In pharmaceutical industries, Werkbite applies data science to optimize drug discovery, personalize treatments, predict patient outcomes,',
            paragraphText2: 'and enhance operational efficiency through advanced analytics and machine learning models.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/img1.jpg'
        },
        {
            subTitle: 'Case study #2',
            title: 'Mathematics, Advanced Statistics in Python',
            paragraphText1: 'Werkbite utilizes mathematics and advanced statistics in Python to build robust data models, perform complex analyses,',
            paragraphText2: 'and derive actionable insights, driving precision and effectiveness in our IT solutions.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/img2.jpg'
        }
    ]

}
class singleCaseStudyItemContent {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    link : string;
    linkText : string;
    linkIcon : string;
    img : string;
}