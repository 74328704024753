import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aboutpage-history',
    templateUrl: './aboutpage-history.component.html',
    styleUrls: ['./aboutpage-history.component.scss']
})
export class AboutpageHistoryComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Our History",
            title: 'History Begins in 2021',
            paragraphText: 'Founded with a vision to revolutionize IT services, Werkbite has grown into a leading provider of innovative solutions, specializing in AI, B2C product development, and strategic consulting.'
        }
    ]
    historyTimeline: TimelineBlock[] = [
        {
            year: '2021',
            date: 'November 21',
            title: 'Founded',
            paragraphText: 'Werkbite, founded in November 2021, is a premier IT solutions provider specializing in AI-driven innovations and B2C product development, dedicated to delivering tailored, scalable, and efficient services for business growth.',
            img: 'assets/img/history/img1.jpg'
        },
        {
            year: '2022',
            date: 'January 22',
            title: 'Global Success',
            paragraphText: 'Werkbite achieved global success in January 2022, rapidly expanding its reach and impact with innovative IT solutions, AI-driven developments, and exceptional B2C product services.',
            img: 'assets/img/history/img2.jpg'
        },
        {
            year: '2023',
            date: 'March 23',
            title: 'Founded Data Center',
            paragraphText: 'In March 2023, Werkbite founded a data center in Delhi, enhancing its infrastructure to provide superior IT solutions, increased data security, and improved service delivery for its clients.',
            img: 'assets/img/history/img3.jpg'
        },
        {
            year: '2024',
            date: 'July 10',
            title: 'Award',
            paragraphText: 'Werkbite received an award for excellence in IT solutions, recognizing its innovative approach, exceptional service, and significant contributions to AI-driven B2C product development.',
            img: 'assets/img/history/img4.jpg'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class TimelineBlock {
    year : string;
    date : string;
    title : string;
    paragraphText : string;
    img : string;
}