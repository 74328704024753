<div class="partner-area ptb-70 bg-fafafb">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-4 col-md-4" *ngFor="let Item of partnerItem;">
                <div class="single-partner-item">
                    <img [src]="Item.img" style="width: 88%;" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>