import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Solutions',
            title: 'We Different From Others Should Choose Us',
            paragraphText: 'Choose Werkbite for its innovative approach, AI-driven solutions, and deep expertise in B2C product development, offering personalized and efficient IT services that make us stand out from the competition.'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'Startup Applications',
            paragraphText: 'Empowering startups with robust, scalable software solutions, Werkbite helps turn visionary ideas into reality. Our expertise in developing intuitives.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-laptop',
            title: 'SaaS Solutions',
            paragraphText: 'Werkbite offers scalable, cloud-based SaaS solutions that streamline business operations, enhance collaboration, and boost productivity.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-money',
            title: 'E-Commerce Platforms',
            paragraphText: 'Werkbite creates dynamic, secure e-commerce platforms to boost sales and enhance customer experience.',
            link: 'services-details',
            linkText: 'View Details'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}