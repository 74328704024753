import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homefour-services',
    templateUrl: './homefour-services.component.html',
    styleUrls: ['./homefour-services.component.scss']
})
export class HomefourServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Services We Can Help You With',
            paragraphText: 'Werkbite offers comprehensive services, including AI solutions, custom software development, B2C product innovation, IT consulting, and support, ensuring your business operates efficiently and scales successfully in a competitive market.'
        }
    ]
    singleServicesBoxItem: ServicesBoxContent[] = [
        {
            icon: 'assets/img/services/icon7.png',
            title: 'Big Data Analysis',
            paragraphText: 'Big data analysis at Werkbite involves processing and examining large, complex datasets using advanced tools and algorithms. This enables businesses to uncover insights, identify trends, and make data-driven decisions to enhance operations and drive growth.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon8.png',
            title: 'Robust Analytics',
            paragraphText: 'Robust analytics at Werkbite involves using advanced techniques and tools to ensure accurate, reliable, and actionable insights from complex data, driving effective decision-making and strategic business improvements.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon9.png',
            title: 'Marketing Analytics',
            paragraphText: 'Marketing analytics at Werkbite analyzes data from various marketing channels to measure campaign performance, understand customer behavior, and optimize strategies. This helps businesses enhance targeting, improve ROI, and drive growth through data-driven marketing decisions.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon10.png',
            title: 'Data Visualization',
            paragraphText: 'Data visualization at Werkbite transforms complex data into clear, intuitive visuals such as charts and graphs, making it easier to interpret insights, identify trends, and communicate findings effectively for informed decision-making.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon11.png',
            title: 'Securities Research',
            paragraphText: 'Securities research at Werkbite involves analyzing financial data, market trends, and investment opportunities to provide actionable insights and recommendations. This helps investors make informed decisions and manage risk effectively in their investment portfolios.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon12.png',
            title: 'Data Governance',
            paragraphText: 'Data governance at Werkbite ensures the proper management, quality, and security of data across an organization. It involves establishing policies, procedures, and standards to ensure data accuracy, compliance, and accessibility, thereby supporting reliable and strategic decision-making.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    linkText : string;
    linkIcon : string;
    link : string;
}