import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-testimonials',
    templateUrl: './homeone-testimonials.component.html',
    styleUrls: ['./homeone-testimonials.component.scss']
})
export class HomeoneTestimonialsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Testimonials",
            title: 'What Our Clients Are Saying?',
            paragraphText: 'Our clients commend Werkbite for its exceptional service, innovative solutions, and reliable support, noting how our tailored IT and software development has significantly enhanced their business performance and growth'
        }
    ]
    singleTestimonialsItem: TestimonialsItemContent[] = [
        {
            clientImg: 'assets/img/testimonials/img1.jpg',
            paragraphText: 'Werkbite Pvt. Ltd. is an exceptional IT provider, delivering innovative solutions with outstanding expertise and reliability. Their commitment to excellence and customer satisfaction truly sets them apart in the industry.',
            clientName: 'Dheeraj Kumar',
            clientDesignation: 'Manager'
        },
        {
            clientImg: 'assets/img/testimonials/img2.jpg',
            paragraphText: 'Werkbite Pvt. Ltd. is a top-tier IT provider, known for their cutting-edge technology and exceptional service. Their ability to deliver tailored solutions with professionalism and efficiency makes them a standout choice in the industry.',
            clientName: 'David Warner',
            clientDesignation: 'IT Services'
        },
        {
            clientImg: 'assets/img/testimonials/img3.jpg',
            paragraphText: 'Werkbite Pvt. Ltd. excels as an IT provider, offering state-of-the-art solutions and unparalleled support. Their dedication to innovation and client success makes them a trusted leader in the technology sector.',
            clientName: 'Sarah Taylor',
            clientDesignation: 'Microsoft Startup Meet'
        }
    ]
    testimonialsBtn: Btn[] = [
        {
            link: "/",
            icon: 'flaticon-view',
            text: 'Check Out All Reviews'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class TestimonialsItemContent {
    paragraphText : string;
    clientImg : string;
    clientName : string;
    clientDesignation : string;
}
class Btn {
    link : string;
    icon : string;
    text : string;
}