import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-page',
    templateUrl: './services-page.component.html',
    styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Services'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'Startup Applications',
            paragraphText: 'Empowering startups with robust, scalable software solutions, Werkbite helps turn visionary ideas into reality. Our expertise in developing intuitives.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-laptop',
            title: 'SaaS Solutions',
            paragraphText: 'Werkbite offers scalable, cloud-based SaaS solutions that streamline business operations, enhance collaboration, and boost productivity.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-money',
            title: 'E-Commerce Platforms',
            paragraphText: 'Werkbite offers scalable, cloud-based SaaS solutions that streamline business operations, enhance collaboration, and boost productivity.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-segmentation',
            title: 'Research',
            paragraphText: 'Werkbite is committed to continuous research, focusing on advancements in AI, data analytics, and IT solutions to drive innovation, improve services, and deliver cutting-edge technologies to clients.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-analytics',
            title: 'Analytics',
            paragraphText: 'Werkbite excels in analytics, leveraging advanced data analysis techniques and machine learning to uncover insights, optimize strategies, and drive informed business decisions for our clients.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-settings',
            title: 'Technology',
            paragraphText: 'Werkbite harnesses the latest technology to deliver innovative IT solutions, incorporating AI, machine learning, and advanced software development to meet the evolving needs of our clients.',
            link: 'services-details',
            linkText: 'View Details'
        }
    ]

}
class pageTitle {
    title : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}