import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    contactForm: FormGroup;
    constructor(private fb: FormBuilder, private http: HttpClient) {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone_Number: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    onSubmit() {
        debugger;
        if (this.contactForm.valid) {

            const formData = this.contactForm.value;
            formData.supportTypeId = 1;
            formData.systemId = 1;
            formData.subject = 'Contact Us Message';
            this.http.post(environment.api_mgmt_service_url + '/buywork/support/dev/api/Support/Add', formData).subscribe(
                response => {
                    alert('Form submitted successfully');
                    console.log('Form submitted successfully', response);
                },
                error => {
                    alert('Error');
                    console.error('Error submitting form', error);
                }
            );
        }
    }


    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Contact Us'
        }
    ]
    contactInfoBox1: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Our Address',
            location: 'G40 KSN, Delhi 110041, India'
        }
    ]
    contactInfoBox2: InfoBox2[] = [
        {
            icon: 'bx bx-phone-call',
            title: 'Contact',
            number: '+91 8383 008368',
            email: 'support@werkbite.com'
        }
    ]
    contactInfoBox3: InfoBox3[] = [
        {
            icon: 'bx bx-time-five',
            title: 'Hours of Operation',
            text1: 'Monday - Friday: 09:00 - 20:00',
            text2: 'Sunday & Saturday: 10:30 - 22:00'
        }
    ]

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Get In Touch",
            title: 'Ready to Get Started?',
            paragraphText: 'Your email address will not be published. Required fields are marked *'
        }
    ]
    contactImage: Image[] = [
        {
            img: 'assets/img/contact.png'
        }
    ]

}
class pageTitle {
    title: string;
}
class InfoBox1 {
    icon: string;
    title: string;
    location: string;
}
class InfoBox2 {
    icon: string;
    title: string;
    number: string;
    email: string;
}
class InfoBox3 {
    icon: string;
    title: string;
    text1: string;
    text2: string;
}

class sectionTitleContent {
    subTitle: string;
    title: string;
    paragraphText: string;
}
class Image {
    img: string;
}