import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-hometwo-services',
    templateUrl: './hometwo-services.component.html',
    styleUrls: ['./hometwo-services.component.scss']
})
export class HometwoServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Our Services",
            title: 'We Offer Professional Solutions For Business',
            paragraphText: 'Werkbite offers professional solutions for businesses, including custom software development, AI integration, and data analytics, tailored to enhance operations, drive innovation, and achieve growth.'
        }
    ]
    singleServicesItem: ServicesItem[] = [
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Customer Analytics',
            paragraphText: 'Werkbite’s customer analytics leverages data to understand customer behavior, optimize marketing strategies, and enhance experiences, driving loyalty and enabling informed, data-driven business decisions.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'Risk Analytics',
            paragraphText: 'Werkbite’s risk analytics identifies, assesses, and mitigates potential business risks using advanced data analysis, predictive modeling, and AI, helping businesses make informed decisions and ensure stability.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Social Media Analytics',
            paragraphText: 'Werkbite’s social media analytics harnesses data from various platforms to measure engagement, track trends, and optimize strategies, enhancing brand presence and driving targeted business growth.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon4.png',
            title: 'Real Time Analytics',
            paragraphText: 'Werkbite’s real-time analytics processes data instantly, providing immediate insights and enabling quick decision-making to optimize operations, enhance customer experiences, and drive business agility.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon5.png',
            title: 'Big Data Analytics',
            paragraphText: 'Werkbite’s big data analytics leverages advanced technologies to process and analyze vast datasets, uncovering valuable insights, optimizing strategies, and driving informed, data-driven business decisions for enhanced performance.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon6.png',
            title: 'Business Analytics',
            paragraphText: 'Werkbite’s business analytics integrates data analysis and advanced modeling to provide actionable insights, optimize operations, improve decision-making, and drive business growth and efficiency.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class ServicesItem {
    icon : string;
    title : string;
    paragraphText : string;
    btnIcon : string;
    btnText : string;
    link : string;
}