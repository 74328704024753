import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homethree-testimonials',
    templateUrl: './homethree-testimonials.component.html',
    styleUrls: ['./homethree-testimonials.component.scss']
})
export class HomethreeTestimonialsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'What Our Learners are Saying?',
            paragraphText: 'Our learners praise Werkbite’s online data science courses for their practical content, expert instructors, and effective learning methods, significantly enhancing their skills and career prospects.'
        }
    ]
    singleTestimonialsBox: TestimonialsBoxContent[] = [
        {
            clientImg: 'assets/img/testimonials/img1.jpg',
            paragraphText: 'Werkbite Pvt. Ltd. is an exceptional IT provider, delivering innovative solutions with outstanding expertise and reliability. Their commitment to excellence and customer satisfaction truly sets them apart in the industry.',
            clientName: 'Dheeraj Kumar',
            clientDesignation: 'Manager'
        },
        {
            clientImg: 'assets/img/testimonials/img2.jpg',
            paragraphText: 'Werkbite Pvt. Ltd. is a top-tier IT provider, known for their cutting-edge technology and exceptional service. Their ability to deliver tailored solutions with professionalism and efficiency makes them a standout choice in the industry.',
            clientName: 'David Warner',
            clientDesignation: 'IT Services'
        },
        {
            clientImg: 'assets/img/testimonials/img3.jpg',
            paragraphText: 'Werkbite Pvt. Ltd. excels as an IT provider, offering state-of-the-art solutions and unparalleled support. Their dedication to innovation and client success makes them a trusted leader in the technology sector.',
            clientName: 'Sarah Taylor',
            clientDesignation: 'Microsoft Startup Meet'
        }
    ]
    testimonialsBtn: Btn[] = [
        {
            link: "/",
            icon: 'flaticon-view',
            text: 'Check Out All Reviews'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class TestimonialsBoxContent {
    clientImg : string;
    paragraphText : string;
    clientName : string;
    clientDesignation : string;
}
class Btn {
    link : string;
    icon : string;
    text : string;
}