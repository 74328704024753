import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homefour-featured-services',
    templateUrl: './homefour-featured-services.component.html',
    styleUrls: ['./homefour-featured-services.component.scss']
})
export class HomefourFeaturedServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'How We Take Your Business From Good To Great',
            paragraphText: 'Werkbite transforms your business from good to great with innovative AI solutions, expert B2C product development, and tailored IT services, enhancing efficiency, scalability, and overall performance to achieve exceptional growth.'
        }
    ]
    singleFeaturedServicesBox: featuredServicesContent[] = [
        {
            icon: 'flaticon-analysis',
            title: 'Analyze Your Data Needs',
            paragraphText: 'Analyzing your data needs with Werkbite involves assessing current data assets, identifying gaps, and determining objectives to tailor data solutions that drive insights, optimize operations, and support strategic decision-making.',
            linkIcon: 'flaticon-right',
            linkText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'flaticon-structure',
            title: 'Develop A Customized Plan',
            paragraphText: 'Werkbite develops a customized plan by assessing your unique business requirements, defining clear objectives, and designing tailored IT solutions to enhance efficiency, drive growth, and achieve strategic goals.',
            linkIcon: 'flaticon-right',
            linkText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'flaticon-idea',
            title: 'Implement Your Solution',
            paragraphText: 'Werkbite implements your solution by executing the customized plan, integrating the tailored IT systems, and ensuring seamless deployment. We manage the process to ensure effective operation and address any issues for optimal performance.',
            linkIcon: 'flaticon-right',
            linkText: 'Read More',
            link: 'services-details'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class featuredServicesContent {
    icon : string;
    title : string;
    paragraphText : string;
    linkIcon : string;
    linkText : string;
    link : string;
}