import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-case-studies-details-page',
    templateUrl: './case-studies-details-page.component.html',
    styleUrls: ['./case-studies-details-page.component.scss']
})
export class CaseStudiesDetailsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Case Studies Details',
            subTitle: 'Data Science in Pharmaceutical Industries',
        }
    ]

    caseStudiesDetailsImage: Image[] = [
        {
            img: 'assets/img/projects/projects-details1.jpg'
        }
    ]
    caseStudiesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Data Science',
            title1: 'Complete Data Science Training: Mathematics, Statistics, Python, Advanced Statistics in Python',
            paragraph1: 'Werkbite offers comprehensive IT services designed to transform B2C concepts using advanced AI technologies. Our expertise spans custom software development, data analytics, and AI integration, allowing businesses to innovate and enhance their customer experiences.',
            img1: 'assets/img/projects/img2.jpg',
            title2: 'Important Facts',
            paragraph2: 'By leveraging cutting-edge technology, we provide scalable and efficient solutions tailored to meet the unique needs of our clients. Our services enable businesses to stay competitive, optimize operations, and achieve sustainable growth in a rapidly evolving digital landscape.',
            paragraph3: ' With a commitment to excellence and innovation, Werkbite ensures your business is equipped with the tools and insights needed for success.',
            title3: 'Results',
            paragraph4: 'Werkbite delivers AI-driven IT services, transforming B2C concepts through innovative technology, enhancing customer experiences, optimizing operations, and driving sustainable business growth.',
        }
    ]
    caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'The Field of Data Science'
        },
        {
            title: 'The Problem'
        },
        {
            title: 'The Solution'
        },
        {
            title: 'The Skills'
        },
        {
            title: 'Statistics'
        },
        {
            title: 'Mathematics'
        }
    ]
    caseStudiesDetailsInfo: Info[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Client',
            subTitle: 'Werkbite',
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'India',
        },
        {
            icon: 'bx bx-purchase-tag',
            title: 'Technologies',
            subTitle: 'FlutterFlow, Azure, .Net, Data Science',
        },
        {
            icon: 'bx bx-check',
            title: 'Completed',
            subTitle: '28 April 2024',
        },
        {
            icon: 'bx bx-globe',
            title: 'Website',
            subTitle: 'Wardrobe',
        }
    ]

}
class pageTitle {
    title : string;
    subTitle : string;
}
class Image {
    img : string;
}
class DetailsDesc {
    subTitle : string;
    title1 : string;
    title2 : string;
    title3 : string;
    img1 : string;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    paragraph4: string;
}
class ImportantFacts {
    title : string;
}
class Info {
    icon : string;
    title : string;
    subTitle : string;
}