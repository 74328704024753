import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homefour-what-we-do',
    templateUrl: './homefour-what-we-do.component.html',
    styleUrls: ['./homefour-what-we-do.component.scss']
})
export class HomefourWhatWeDoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'What We Do',
            paragraphText: 'Werkbite delivers innovative IT solutions, including custom software development, AI integration, and strategic consulting, designed to optimize operations and drive business growth in the B2C sector.'
        }
    ]
    singleWhatWeDoBox: whatWeDoContent[] = [
        {
            icon: 'flaticon-segmentation',
            title: 'Research',
            paragraphText: 'Werkbite excels in research by exploring emerging technologies, analyzing market trends, and developing cutting-edge solutions. This rigorous approach ensures we deliver innovative, data-driven strategies and technologies that keep our clients ahead of the curve.',
            linkText1: 'Market Research',
            link1: 'services-details',
            linkText2: 'Investment Research',
            link2: 'services-details',
            linkText3: 'Read More',
            link3: 'services-details'
        },
        {
            icon: 'flaticon-analytics',
            title: 'Analytics',
            paragraphText: 'Werkbite specializes in analytics, applying advanced techniques to interpret complex data, uncover insights, and optimize strategies. Our data-driven approach helps businesses enhance decision-making, improve performance, and drive growth.',
            linkText1: 'Data Analytics',
            link1: 'services-details',
            linkText2: 'Business Intelligence',
            link2: 'services-details',
            linkText3: 'Read More',
            link3: 'services-details'
        },
        {
            icon: 'flaticon-settings',
            title: 'Technology',
            paragraphText: 'Werkbite leverages the latest technology to deliver innovative IT solutions, integrating advanced tools and systems to enhance efficiency, drive business growth, and stay ahead in a rapidly evolving digital landscape.',
            linkText1: 'Intelligence Automation',
            link1: 'services-details',
            linkText2: 'Quality Engineering',
            link2: 'services-details',
            linkText3: 'Read More',
            link3: 'services-details'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class whatWeDoContent {
    icon : string;
    title : string;
    paragraphText : string;
    linkText1 : string;
    link1 : string;
    linkText2 : string;
    link2 : string;
    linkText3 : string;
    link3 : string;
}