import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Frequently Asked Questions'
        }
    ]
    faqAccordion: Accordion[] = [
        {
            questions: 'Q1. What is Data Science? List the differences between supervised and unsupervised learning.',
            answer: "Data science is an interdisciplinary field that uses scientific methods, algorithms, and systems to extract insights from structured and unstructured data. It integrates statistics, computer science, and domain expertise to analyze and interpret complex datasets, informing decision-making and solving intricate problems."
        },
        {
            questions: 'Q2. What is Selection Werkbite.com?',
            answer: "Selection Werkbite.com refers to choosing Werkbite as your IT solutions provider. Werkbite offers innovative, AI-driven services tailored to transform B2C concepts, enhance customer experiences, and drive business growth. With expertise in custom software development, data analytics, and strategic consulting, Werkbite delivers scalable, efficient solutions designed to meet the unique needs of each client. By leveraging cutting-edge technology and a commitment to excellence, Werkbite ensures your business is equipped with the tools and insights needed to thrive in a competitive digital landscape. Choosing Werkbite means partnering with a dedicated team focused on your success."
        },
        {
            questions: 'Q3. What is Werkbite-variance trade-off?',
            answer: "The Werkbite-variance trade-off concept reflects the balance between bias and variance in machine learning models, essential for achieving optimal performance. Bias refers to errors due to overly simplistic models that may underfit data, while variance pertains to errors from models that are too complex and overfit data. At Werkbite, we carefully manage this trade-off to develop models that generalize well to new data, ensuring accurate and reliable predictions. By leveraging advanced techniques and expertise, Werkbite achieves the right balance, delivering robust, effective AI-driven solutions tailored to your business needs."
        },
        {
            questions: 'Q4. What is a confusion matrix?',
            answer: "A confusion matrix is a performance measurement tool for classification models in machine learning, widely used by Werkbite to evaluate and refine their AI-driven solutions. It is a table that describes the performance of a model by showing the actual versus predicted classifications. "
        },
        {
            questions: 'Q5. What is the difference between “long” and “wide” format data?',
            answer: "Long format data organizes each observation as a single row, with columns typically representing identifiers, variable types, and values. This format is advantageous for time series and repeated measures, as it simplifies the aggregation and analysis of data over time or multiple conditions. In contrast, wide format data arranges variables in separate columns, with each row representing a single subject or unit, making it easier for comparison between variables within the same observation. While wide format is often used for straightforward comparisons, long format is generally more flexible and suitable for complex analyses involving multiple observations or measurements."
        }
    ]

}
class pageTitle {
    title : string;
}
class Accordion {
    questions : string;
    answer : string;
}