import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-process',
    templateUrl: './homeone-process.component.html',
    styleUrls: ['./homeone-process.component.scss']
})
export class HomeoneProcessComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "How It's Work",
            title: 'The Data Science Process',
            paragraphText: 'The data science process: collect, clean, analyze, model, evaluate, deploy, and monitor data for actionable insights.'
        }
    ]
    singleProcessBox: processBoxContent[] = [
        {
            img: 'assets/img/process/img1.png',
            title: 'Frame the Problem',
            paragraphText: 'Frame the problem by defining objectives, understanding constraints, and identifying key variables to guide data collection and analysis for effective solutions.',
            number: '1'
        },
        {
            img: 'assets/img/process/img2.png',
            title: 'Collect the Raw Data',
            paragraphText: 'sourcing relevant information from databases, APIs, and surveys to ensure comprehensive and accurate datasets for analysis.',
            number: '2'
        },
        {
            img: 'assets/img/process/img3.png',
            title: 'Process the Data',
            paragraphText: 'Process the data by cleaning, transforming, and organizing it to ensure accuracy and prepare it for analysis and model building.',
            number: '3'
        },
        {
            img: 'assets/img/process/img4.png',
            title: 'Explore the Data',
            paragraphText: 'Explore the data by analyzing patterns, trends, and correlations to gain insights and understand its structure, helping inform further analysis and model development.',
            number: '4'
        },
        {
            img: 'assets/img/process/img5.png',
            title: 'Perform In-depth Analysis',
            paragraphText: 'Perform in-depth analysis by applying statistical methods, algorithms, and visualization techniques to extract meaningful insights and uncover trends and relationships in the data.',
            number: '5'
        },
        {
            img: 'assets/img/process/img6.png',
            title: 'Communicate Results',
            paragraphText: 'Communicate results by presenting clear, actionable insights through reports, visualizations, and summaries, making complex findings accessible and understandable for decision-makers.',
            number: '6'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class processBoxContent {
    img : string;
    title : string;
    paragraphText : string;
    number : string;
}