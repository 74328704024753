import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-hometwo-boxes',
    templateUrl: './hometwo-boxes.component.html',
    styleUrls: ['./hometwo-boxes.component.scss']
})
export class HometwoBoxesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleBoxesItem: BoxItem[] = [
        {
            icon: 'assets/img/icon/icon1.png',
            title: 'Marketing Analytics',
            paragraphText: 'Werkbite’s marketing analytics analyzes data from various channels to optimize campaigns, understand customer behavior, and improve ROI, driving targeted strategies and business growth.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'Consumer Analytics',
            paragraphText: 'Werkbite’s consumer analytics examines customer data to uncover behavior patterns, preferences, and trends, enabling businesses to tailor strategies, enhance experiences, and boost engagement and loyalty.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Sales Analytics',
            paragraphText: 'Werkbite’s sales analytics leverages data to analyze sales performance, identify trends, and optimize strategies, helping businesses improve forecasting, boost revenue, and drive growth through informed decision-making.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        }
    ]

}
class BoxItem {
    icon : string;
    title : string;
    paragraphText : string;
    btnIcon : string;
    btnText : string;
    link : string;
}