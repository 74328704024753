import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-details-page',
    templateUrl: './services-details-page.component.html',
    styleUrls: ['./services-details-page.component.scss']
})
export class ServicesDetailsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Service Details',
            subTitle: 'AI & ML Development',
        }
    ]
    servicesDetailsImage: DetailsImage[] = [
        {
            img: 'assets/img/services/services-details1.jpg'
        }
    ]
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'AI & ML Development',
            title1: 'About this Services',
            paragraphText1: 'Werkbite offers comprehensive IT services designed to transform B2C concepts using advanced AI technologies. Our expertise spans custom software development, data analytics, and AI integration, allowing businesses to innovate and enhance their customer experiences.',
            paragraphText2: 'By leveraging cutting-edge technology, we provide scalable and efficient solutions tailored to meet the unique needs of our clients. Our services enable businesses to stay competitive, optimize operations, and achieve sustainable growth in a rapidly evolving digital landscape.',
            title2: 'Important Facts',
            title3: 'Application Areas',
            title4: 'Technologies That We Use',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
        }
    ]
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'The Field of Data Science'
        },
        {
            title: 'The Problem'
        },
        {
            title: 'The Solution'
        },
        {
            title: 'The Skills'
        },
        {
            title: 'Statistics'
        },
        {
            title: 'Mathematics'
        }
    ]
    servicesDetailsDescApplication: Application[] = [
        {
            title: 'Manufacturing',
            icon: 'flaticon-factory'
        },
        {
            title: 'Healthcare',
            icon: 'flaticon-hospital'
        },
        {
            title: 'Automobile',
            icon: 'flaticon-tracking'
        },
        {
            title: 'Banking',
            icon: 'flaticon-investment'
        },
        {
            title: 'Real Estate',
            icon: 'flaticon-house'
        },
        {
            title: 'Logistics',
            icon: 'flaticon-order'
        }
    ]
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: 'JavaScript'
        },
        {
            title: 'Python'
        },
        {
            title: 'Java'
        },
        {
            title: 'C/CPP'
        },
        {
            title: 'PHP'
        },
        {
            title: 'Swift'
        },
        {
            title: 'C# (C- Sharp)'
        },
        {
            title: 'Ruby'
        },
        {
            title: 'SQL'
        }
    ]

    sidebarServicesList: ServicesList[] = [
        {
            title: 'AI & ML Development',
            link: 'services-details'
        },
        {
            title: 'Data Analytics',
            link: 'services-details'
        },
        {
            title: 'Data Science',
            link: 'services-details'
        },
        {
            title: 'Artificial Intelligence',
            link: 'services-details'
        },
        {
            title: 'Data Visualization',
            link: 'services-details'
        }
    ]
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'PDF Download',
            icon: 'bx bxs-file-pdf',
            link: 'services-details'
        },
        {
            title: 'Services Details.txt',
            icon: 'bx bxs-file-txt',
            link: 'services-details'
        }
    ]
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Phone',
            subTitle: '+91 8383008368'
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'India'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'support@Werkbite.com'
        }
    ]
    
}
class pageTitle {
    title : string;
    subTitle : string;
}
class DetailsImage {
    img : string;
}
class DetailsDesc {
    subTitle : string;
    title1 : string;
    title2 : string;
    title3 : string;
    title4 : string;
    paragraphText1 : string;
    paragraphText2 : string;
    img1 : string;
    img2 : string;
}
class ImportantFacts {
    title : string;
}
class Application {
    title : string;
    icon: string;
}
class TechnologiesFeatures {
    title : string;
}

class ServicesList {
    title : string;
    link : string;
}
class DownloadFile {
    title : string;
    icon : string;
    link : string;
}
class ContactInfo {
    icon : string;
    title : string;
    subTitle : string;
}