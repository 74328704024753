import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-services',
    templateUrl: './homeone-services.component.html',
    styleUrls: ['./homeone-services.component.scss']
})
export class HomeoneServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Services',
            title: 'We Offer Professional Solutions For Business',
            paragraphText: 'We offer professional solutions for businesses, including custom software development, AI integration, and strategic IT consulting, designed to optimize operations and drive growth.'
        }
    ]
    singleServicesBox: ServicesBoxContent[] = [
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Data Analytics',
            paragraphText: 'Werkbite maximizes business potential with powerful data analytics solutions, uncovering actionable insights for informed decision-making and strategic growth.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'AI & ML Development',
            paragraphText: 'Werkbite pioneers AI and ML solutions, crafting intelligent systems that drive efficiency, innovation, and competitive advantage for your business.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Data Science',
            paragraphText: 'Werkbite harnesses data science to empower businesses with actionable insights and predictive analytics, driving informed decisions and transformative outcomes.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon4.png',
            title: 'Predictive Analytics',
            paragraphText: 'Werkbite’s predictive analytics services use advanced algorithms and data modeling to forecast future trends, helping businesses make informed decisions and gain a competitive edge.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon5.png',
            title: 'Software Development',
            paragraphText: 'Werkbite specializes in software development, delivering custom, scalable solutions tailored to your business needs, enhancing functionality, and driving innovation.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon6.png',
            title: 'Elastic Solutions',
            paragraphText: 'Werkbite offers elastic solutions that provide scalable, flexible IT services, adapting to your business changing needs and ensuring optimal performance and efficiency as you grow and evolve.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        }
    ]
    loadMoreBtn: loadMore[] = [
        {
            link: 'services',
            linkText: 'Load More',
            linkIcon: 'flaticon-refresh'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
    linkIcon : string;
}
class loadMore {
    link : string;
    linkText : string;
    linkIcon : string;
}