import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-about',
    templateUrl: './homeone-about.component.html',
    styleUrls: ['./homeone-about.component.scss']
})
export class HomeoneAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/about/img1.png'
        }
    ]
    aboutContent: Content[] = [
        {
            subTitle: 'About Us',
            title: 'Drive the Digital Revolution with Our Cloud-Based AI and Analytics Solutions',
            paragraphText1: 'Werkbite is a forward-thinking cloud-based startup specializing in AI and analytics solutions. Founded in November 2021, we are dedicated to solving everyday business challenges through innovative technology.',
            paragraphText2: 'Our strategic partnerships with Microsoft Azure and Google empower us to deliver cutting-edge, scalable, and reliable solutions tailored to meet the unique needs of our clients. At Werkbite, we are committed to driving business growth and efficiency by leveraging the latest advancements in technology and providing exceptional service.',
            defaultBtnIcon: 'flaticon-right',
            defaultBtnText: 'More About Us',
            defaultBtnLink: 'about-us'
        }
    ]
    featuresList: List[] = [
        {
            icon: 'assets/img/icon1.png',
            title: '2 Years',
            subTitle: 'On the market'
        },
        {
            icon: 'assets/img/icon2.png',
            title: '15+',
            subTitle: 'Team members'
        },
        {
            icon: 'assets/img/icon3.png',
            title: '100%',
            subTitle: 'Satisfaction rate'
        },
        {
            icon: 'assets/img/icon4.png',
            title: '80%',
            subTitle: 'Senior scientist'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    defaultBtnIcon : string;
    defaultBtnText : string;
    defaultBtnLink : string;
}
class List {
    icon : string;
    title : string;
    subTitle : string;
}